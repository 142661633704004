<style scoped>
	.tmp_big{width: 100%;height: 100%;}
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{
	  background-color: #67CD80 !important;
	  color: #fff;
	}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
	.el-table-column--selection{text-align: center;}
	.el-table-column--selection .cell{text-align: center;}
</style>
<template>
	<div class="tmp_big">
		<div class="tpm_box">
			<div class="tem_header">
				<div class="mb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>{{thisPageParams.community.name}}</el-breadcrumb-item>
						<el-breadcrumb-item>{{thisPageParams.biaozhun.name}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<el-button type="warning" size="small" @click="add">添加项目<i class="el-icon-circle-plus-outline"></i></el-button>
				<el-button type="warning" size="small" @click="deleteBuild">移除所选项目<i class="el-icon-delete"></i></el-button>
				<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="tem_body" id="tem_body">
				<div class="tem_body_contair" id="table_box" v-loading="tableLoading">
					<el-table :data="tableData" :span-method="objectSpanMethod" :height="tableHeight" border @row-click="rowClick"
					 @selection-change="handleSelectionChange">
						<el-table-column prop="itemInfo.name" label="项目名字" width=""></el-table-column>
						<el-table-column type="selection" width="55" style="text-align: center;"></el-table-column>
						<el-table-column prop="formulaInfo.name" label="收费公式" width=""></el-table-column>
						<el-table-column prop="formulaInfo.charge_param" label="计费参数" width=""  :formatter="formatterChargeParam"></el-table-column>
						<el-table-column prop="formulaInfo.fixed_unit_price" label="定额单价" width=""></el-table-column>
						<el-table-column prop="formulaInfo.fixed_coefficient" label="固定系数" width=""></el-table-column>
					</el-table>
				</div>
			</div>
			<div class="tem_footer">
				<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
				 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
		<!-- 添加弹窗 -->
		<el-dialog custom-class="my_dialog" width="40%" top="none" title="添加项目" :visible.sync="addDialog" destroy-on-close>
			<add v-if="addDialog" :pageParams="thisPageParams" v-on:childrenEvent="addEvent"></add>
		</el-dialog>
		<!-- 编辑弹窗 -->
		<!-- <el-dialog custom-class="my_dialog" width="40%" top="none" title="修改业主" :visible.sync="editDialog" destroy-on-close>
			<owner_edit v-if="editDialog" :pageParams="thisPageParams" :mainObject="selectTableRow" v-on:childrenEvent="editEvent"></owner_edit>
		</el-dialog> -->
	</div>
</template>

<script>
	import {getChargeParamByValue} from '../../../assets/js/config.js';
	import add from './add.vue';
	// import owner_edit from './owner_edit.vue';
	var _this;
	export default {
		components: {
			add
		},
		props: ['pageParams'],
		data() {
			return {
				thisPageParams: null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				selectTableRow: null, // 选中的表格某行
				addDialog: false, // 添加房间弹窗
				editDialog: false,
				checkedItems: [],
			}
		},
		created: function() {
			_this = this;
			_this.thisPageParams = _this.pageParams;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			/* * 刷新 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			},
			/* * 切换分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			/* * 获取表格数据 */
			api_getTableData() {
				_this.tableLoading = true;
				_this._getApi('/wy/chargeStandard/getItemList', {
					standard_id: _this.thisPageParams.biaozhun.id
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data;
						baseData.forEach(function(item) {
							item.rowspan = 1
						});
						// 双层循环
						// for (var i = 0; i < baseData.length; i++) {
						// 	for (var j = i + 1; j < baseData.length; j++) {
						// 		if (baseData[i].item_id === baseData[j].item_id) {
						// 			baseData[i].rowspan++
						// 			baseData[j].rowspan--
						// 		console.log(baseData.slice(i,1));
						// 			console.log(baseData.length);
						// 		}
						// 	}
						// 	// 这里跳过已经重复的数据
						// 	// i = i + baseData[i].rowspan - 1
						// }
						_this.pageTotal = res.data.count;
						_this.tableData = baseData;
						_this.tableLoading = false;
					} else {

					}
				}).catch((err) => {});
			},
			/* ----------
			合并单元格
			----------*/
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 0) {
					return {
						rowspan: row.rowspan,
						colspan: 1
					}
				}
			},
			/* ----------
			渲染计费参数
			----------*/
			formatterChargeParam(row, column, cellValue, index){
				var newCellValue = '';
				newCellValue = getChargeParamByValue(cellValue);
				return newCellValue;
			},
			
			/* ----------
			选择框监听
			----------*/
			handleSelectionChange(val) {
				_this.checkedItems = val;
			},

			/* ----------
			添加
			----------*/
			add() {
				_this.addDialog = true;
			},

			/* * 选择某行 */
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},

			/* ----------
			- 删除
			----------*/
			deleteBuild() {
				var baseData = _this.checkedItems;
				if (baseData.length==0) {
					_this.$alert('请先选择数据进行删除,点击选择框即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				var msgStr = '';
				var idss = [];
				baseData.forEach(function(item){
					msgStr += '【'+item.itemInfo.name + '-' + item.formulaInfo.name + '】 ';
					idss.push(item.id)
				});
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('span', {
							style: 'color: red'
						}, msgStr + ' 吗？')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							_this._postApi('/wy/chargeStandard/standardRemoveItem', {
								ids: idss
							}).then((res) => {
								if (res.code == 1) {
									_this.thisReload();
									_this.selectTableRow = null;
									_this.$message({
										message: '删除成功！',
										type: 'success',
										duration: 1000,
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								} else {
									_this.$message({
										message: res.msg,
										type: 'error',
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								}
							}).catch((err) => {});
						} else {
							done();
						}
					}
				}).then(action => {

				}).catch(() => {});
			},
			// _ *** 添加事件消息
			addEvent(e) {
				switch (e.type) {
					case 'success':
						_this.api_getTableData();
						_this.addDialog = false;
						break;
					case 'close':
						_this.addDialog = false;
						break;
				}
			},
			editEvent(e) {
				switch (e.type) {
					case 'edit':
						_this.api_getTableData();
						_this.editDialog = false;
						break;
					case 'close':
						_this.thisReload();
						_this.editDialog = false;
						break;
				}
			}
		}
	}
</script>
