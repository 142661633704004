<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="100px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="收费标准名称">
							<el-input placeholder="请输入收费标准名称" v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">

				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props:['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				formData: {
					name:''
				}
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {

		},
		methods: {
			// _ ** 点击取消
			clickCancel(){
				_this.$emit('childrenEvent',{
					type:'close'
				});
			},
			
			// _ ** 添加保存
			api_saveCommit(){
				_this.btnSaveLoading = true;
				var params = _this.formData;
				params.residence_id = _this.pageParams.community.id;
				_this._postApi('/wy/chargeStandard/create', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent',{type:'success'});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
				
						}).catch(() => {
				
						});
					}
				}).catch((err) => {
				
				})
			}
		}
	}
</script>
