<style scoped>

</style>
<template>
	<div class="con_page_container">
		<!-- 左边数据树 -->
		<div class="left_tree_box">
			<el-tree :props="props" node-key="id" ref="tree" :current-node-key="selectCommunityId" :load="loadNode" lazy
			 :expand-on-click-node="false" :highlight-current="true" @node-click="treeClick"></el-tree>
		</div>
		<!-- 内容 -->
		<div class="main_container">
			<div class="ch_temp_box">
				<list v-if="showView.type==1" :key="selectCommunityId" v-on:childrenEvent="listEvent" :pageParams="upPageParams"></list>
				<project_list v-if="showView.type==2" :key="upPageParams.biaozhun.id" :pageParams="upPageParams"></project_list>
			</div>
		</div>
	</div>
</template>

<script>
	import list from './list.vue';
	import project_list from './project_list/project_list.vue';
	var _this;
	export default {
		components: {
			list,
			project_list
		},
		data() {
			return {
				communityList: [], //小区列表
				props: {
					label: 'name',
					children: 'zones',
					isLeaf: 'leaf'
				},
				showView: {
					type: 0
				},
				selectCommunityId: '', //选择的小区id
				upPageParams: {}, //_
				listView: false,
				projectListView: false,
				addDialog:false,
				addPageParams:null,
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {

		},
		methods: {
			/* * 获取小区列表 tree加载 */
			loadNode(node, resolve) {
				if (node.level === 0) {
					//获取小区
					_this._getApi('/wy/account/getResidenceListByAdmin', {}).then((res) => {
						if (res.code == 1) {
							var baseData = res.data;
							baseData.forEach(function(item) {
								item.leaf = false;
							})
							_this.selectCommunityId = baseData[0].id;
							_this.communityList = baseData;
							_this.$nextTick(function() {
								_this.$refs.tree.setCurrentKey(_this.selectCommunityId); //获取已经设置的资源后渲染
							})
							_this.upPageParams.community = baseData[0];
							_this.showView.type = 1;
							return resolve(baseData);
						} else {

						}
					}).catch((err) => {})
				}
				if (node.level === 1) {
					_this._getApi('/wy/chargeStandard/getList', {
						residence_id: node.data.id
					}).then((res) => {
						if (res.code == 1) {
							var baseData = res.data.list
							baseData.forEach(function(item) {
								item.leaf = true;
							})
							return resolve(baseData);
						} else {

						}
					}).catch((err) => {});
				}
			},

			/* * tree 点击 */
			treeClick(data, node, _this_) {
				// 小区被点击
				if (node.level == 1) {
					_this.selectCommunityId = data.id;
					_this.upPageParams.community = data;
					_this.showView.type = 1;
				} else if (node.level == 2) {
					var baseData = {};
					var community = node.parent.data;
					baseData.community = community;
					baseData.biaozhun = data;
					_this.upPageParams = baseData;
					_this.showView.type = 2;
				}
			},

			/* *  组件通信 */
			listEvent(e) {
				switch (e.type) {
					case 'add':
						var node = _this.$refs.tree.getNode(_this.upPageParams.community.id); //要刷新的tree id
						node.loaded = false;
						node.expand();
						break;
				}
			},
			/* * 单元 组件通信 */
			unitsEvent(e) {
				switch (e.type) {
					case 'add':
						var node = _this.$refs.tree.getNode(_this.unitsPageParams.build.id); //要刷新的tree id
						node.loaded = false;
						node.expand();
						break;
				}
			},

		}
	}
</script>
