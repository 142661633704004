<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
	.tree_box{height: 18rem;overflow-y: auto;}
</style>
<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="0">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="" class="tree_box">
							<el-tree :props="props" :data="treeData" default-expand-all show-checkbox ref="tree" @check="getCheckedKeys"
							 node-key="treeKey" @check-change="handleCheckChange"></el-tree>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">

				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				formData: {
					name: ''
				},
				props: {
					label: 'label',
					children: 'children',
					isLeaf: 'isLeaf',
					disabled:'disabled'
				},
				apiIndex: 0,
				apiIndex_1: 0,
				treeData: [],
				slectList: []
			}
		},
		created: function() {
			_this = this;
			_this.getClassifyByCommiunity();
		},
		mounted: function() {

		},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			// _ ** checkbox选择事件
			getCheckedKeys(val) {
				//_1.获取当前点击的节点
				var thisNode = _this.$refs.tree.getNode(val);
				//_2.获取父元素下面的子节点除了当前节点不选中
				var cNodes = thisNode.parent.data.children;
				cNodes.forEach(function(item){
					var tnode = _this.$refs.tree.getNode(item);
					if(tnode != thisNode){
						tnode.checked = false;
					}
				});
				
				//var res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
				//_3.获取所有选中的节点
				var res_1 = _this.$refs.tree.getCheckedNodes(true, false);
				var baseArr = [];
				res_1.forEach(function(item) {
					var parentObject = _this.$refs.tree.getNode(item).parent;
					if (parentObject.level != 0) {
						baseArr.push({
							item_id: parentObject.data.id,
							formula_id: item.id
						})
					}
				});
				_this.slectList = baseArr;
			},

			handleCheckChange(data, checked, indeterminate) {
				// console.log(data, checked, indeterminate);
			},

			// _ *** 获取小区下面的收费项目大类
			getClassifyByCommiunity() {
				_this._postApi('/wy/chargeStandard/getItemFormulaList', {
					residence_id: _this.pageParams.community.id
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data;
						for (var i = 0; i < baseData.length; i++) {
							baseData[i].treeKey = baseData[i].id + 'a';
							baseData[i].disabled =true;
							var data_2 = baseData[i].children;
							if(data_2.length>0){
								for (var k = 0; k < data_2.length; k++) {
									data_2[k].treeKey = data_2[k].id + 'b';
									data_2[k].disabled =true;
									var data_3 = data_2[k].children;
									if(data_3.length>0){
										for (var t = 0; t < data_3.length; t++) {
											data_3[t].treeKey = data_3[t].id + 'c';
										}
									}
								}
							}
						}
						_this.treeData = res.data;
					}
				}).catch((err) => {
					console.log(err)
				});
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				params.residence_id = _this.pageParams.community.id;
				params.standard_id = _this.pageParams.biaozhun.id;
				params.items = _this.slectList;
				_this._postApi('/wy/chargeStandard/standardAddItem', params).then((res) => {
					if (res.code == 1) {
						_this.$message({
							message: '添加成功',
							type: 'success',
							duration: 1000,
							onClose: function() {
								_this.btnSaveLoading = false;
								_this.$emit('childrenEvent', {
									type: 'success'
								});
							}
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
